var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "deviceHouseForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              queryUrl: _vm.queryUrl,
              submitUrl: _vm.submitUrl,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备房名称",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "请填写设备房名称",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类",
                        prop: "categoryId",
                        rules: [
                          {
                            required: true,
                            message: "请选择分类",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            model: {
                              value: _vm.form.categoryId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "categoryId", $$v)
                              },
                              expression: "form.categoryId",
                            },
                          },
                          "v-select2",
                          _vm.categoryParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "归属项目",
                        prop: "communityId",
                        rules: [
                          {
                            required: true,
                            message: "请选择归属项目",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            on: { onChange: _vm.communityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属组团",
                        prop: "area",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属组团",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.areaOps },
                        model: {
                          value: _vm.form.area,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "area", $$v)
                          },
                          expression: "form.area",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "安装位置",
                        prop: "location",
                        rules: [
                          {
                            required: true,
                            message: "请填写安装位置",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.location,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "location", $$v)
                          },
                          expression: "form.location",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关联蓝牙",
                        prop: "bluetoothId",
                        rules: [
                          {
                            required: true,
                            message: "请选择关联蓝牙",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            ref: "bluetoothSelect",
                            attrs: { subjoin: _vm.bluetoothExtraParams },
                            model: {
                              value: _vm.form.bluetoothId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "bluetoothId", $$v)
                              },
                              expression: "form.bluetoothId",
                            },
                          },
                          "v-select2",
                          _vm.bluetoothParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-ueditor", {
                        model: {
                          value: _vm.form.remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "关联设备" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设施设备" } },
                    [
                      _c("chosenListPanel", {
                        attrs: { list: _vm.deviceList },
                        on: {
                          "update:list": function ($event) {
                            _vm.deviceList = $event
                          },
                          select: _vm.toSelectTag,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "设备列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getDeviceListUrl,
          extraParams: _vm.extraParams,
          headers: _vm.deviceTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.deviceList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.deviceList = $event
          },
          "update:back-fill": function ($event) {
            _vm.deviceList = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "设备名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "设备编号" },
                  model: {
                    value: _vm.searchParams.serialNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "serialNum", $$v)
                    },
                    expression: "searchParams.serialNum",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.deviceStatusOps },
                  model: {
                    value: _vm.searchParams.deviceStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "deviceStatus", $$v)
                    },
                    expression: "searchParams.deviceStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "安装位置" },
                  model: {
                    value: _vm.searchParams.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "address", $$v)
                    },
                    expression: "searchParams.address",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "责任人" },
                  model: {
                    value: _vm.searchParams.chargerName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "chargerName", $$v)
                    },
                    expression: "searchParams.chargerName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "维保单位" },
                      model: {
                        value: _vm.searchParams.maintCompanyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "maintCompanyId", $$v)
                        },
                        expression: "searchParams.maintCompanyId",
                      },
                    },
                    "v-select2",
                    _vm.maintCompanyParams,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }