// import { generateMapByOpts } from 'common/utils'

// 状态
const deviceStatusOps = [
  {
    text: '全部',
    value: undefined
  }, {
    text: '在用',
    value: 1
  }, {
    text: '已报废',
    value: 2
  }, {
    text: '停用',
    value: 0
  }
]

export {
  deviceStatusOps
}
